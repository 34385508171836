import React from "react";
import RandomLetterAnimation from "../randomLetter/RandomLetter.js";
import "./about.css";
import TypeWriter from "../typeWriter/TypeWriter.js";

const About = () => {
  return (
    <div className="about-container" >
      <div className="about-wrapper">
        <div className="about-wrapper-top">

        <div> <h2 className="about-h2"><TypeWriter content={`I'M HARUN JUNIOR FRONT-END DEVELOPER`} /></h2></div>
        
        </div>
        
        <div className="about-title">
          <h2><RandomLetterAnimation word={"Someting"}/></h2>
          <h2><RandomLetterAnimation word={"About Me"}/></h2>
        </div>

        <p className="about-desc">
          Creating new designs using your imagination, collaborating with
          different disciplines and pushing the boundaries excites me every day.
          As a designer, I grow by being inspired every day, develop my own
          unique style and constantly renew myself.
        </p>
        <div className="about-grid">
          <div>
            <h3 className="about-grid-title"><RandomLetterAnimation word={"EDUCATION"}/></h3>
            <h4>ANADOLU UNIVERSITY</h4>
            <p>Web Design And Coding</p>
            <h4>USAK UNIVERSITY</h4>
            <p>Radio Television And Cinema</p>
          </div>
          <div>
            <h3 className="about-grid-title"><RandomLetterAnimation word={"EXPERIENCE"}/></h3>
            <h4>Front-End Developer</h4>
            <h4>Lotus Agency</h4>
            <p>
              I design and develop the websites of local companies with
              wordpress or software, do SEO work to increase their visibility in
              search engines, and produce logos and slogans to create brand
              identities.
            </p>
          </div>
          <div>
            <h3 className="about-grid-title"><RandomLetterAnimation word={"HOBBY"}/></h3>
            <p>
              Reading books, watching movies, taking photos, playing music and
              playing games are indispensable in my life. Thanks to these
              hobbies, I both have fun and improve myself.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

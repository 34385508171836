import React, { useEffect, useState } from "react";
import "./cursor.css";
import RandomLetterAnimation from "../randomLetter/RandomLetter";

const CustomCanvasCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isInsideCanvas, setIsInsideCanvas] = useState(false);
  useEffect(() => {
    const canvasElements = document.querySelectorAll(".canvas");

    const updateCursorPosition = (e) => {
      
      setPosition({ x: e.clientX, y: e.clientY });
      if (e.target.classList.contains("active-canvas")) {
        setIcon("X");
        setStatus("CLOSE");
      } else {
        setIcon("+");
        setStatus("show");
      }
    };

    const handleMouseEnter = () => {
      setIsInsideCanvas(true);
    };

    const handleMouseLeave = () => {
      setIsInsideCanvas(false);
    };

    canvasElements.forEach((canvas) => {
      canvas.addEventListener("mouseenter", handleMouseEnter);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      window.addEventListener("mousemove", updateCursorPosition);
    });
    window.addEventListener("mousemove", updateCursorPosition);
  }, []);

  // useEffect(() => {
  //   const canvas = document.querySelector(".canvas");
  //   const canvasActive = document.querySelector(".active-canvas");
  //   if (isInsideCanvas) {
  //     canvas.style.cursor = "none";
  //     if(canvasActive)canvasActive.style.cursor = "none";
  //     console.log('inside')
  //   } else {
  //     canvas.style.cursor = "auto";
  //     if(canvasActive)canvasActive.style.cursor = "auto";
  //   }
  // }, [isInsideCanvas]);

  const [icon, setIcon] = useState();
  const [status, setStatus] = useState("");

  useEffect(() => {
    const canvas = document.querySelectorAll(".custom-canvas");
    const canvasActive = document.querySelectorAll(".active-canvas");

    if (isInsideCanvas) {
      canvas.forEach((c) => {
        c.style.cursor = "none";
        
      });
      canvasActive.forEach((c) => {
        c.style.cursor = "none";
      });
    } else {
      canvas.forEach((c) => {
        c.style.cursor = "auto";
      });
      canvasActive.forEach((c) => {
        c.style.cursor = "auto";
      });

    }
  }, [isInsideCanvas]);

  return (
    <div
      className={`custom-cursor`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        visibility: isInsideCanvas ? "visible" : "hidden",
      }}
    >
      {icon}
      {<RandomLetterAnimation word={status} />}
    </div>
  );
};

export default CustomCanvasCursor;

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./skill.css";
import img1 from './images/1-html.png'
import img2 from './images/2-css.png'
import img3 from './images/3-js.png'
import img4 from './images/4-typescript.png'
import img5 from './images/5-nodejs.png'
import img6 from './images/6-mysql.png'
import img7 from './images/7-mongodb.png'
import img8 from './images/8-react.png'
import img9 from './images/9-angular.png'
import img10 from './images/10-vue.png'
import img11 from './images/11-seo.png'
import img12 from './images/12-wordpress.png'
import img13 from './images/13-figma.png'
import img14 from './images/14-photoshop.png'
import img15 from './images/15-illustrator.png'

const Skills = () => {
  const refs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15
  ]

  const setupMouseTracking = (ref) => {
    let mousepos = { x: 0, y: 0 };
    let requestId;
    let scrollVal;
    let rect;
    let renderedStyles = {
      x: { previous: 0, current: 0, amt: 0.1 },
      y: { previous: 0, current: 0, amt: 0.1 },
    };

    const lerp = (a, b, n) => (1 - n) * a + n * b;

    const getMousePos = (e) => {
      const { clientX, clientY } = e;
      mousepos.x = clientX;
      mousepos.y = clientY;
    };

    const calculateSizePosition = () => {
      scrollVal = { x: window.scrollX, y: window.scrollY };
      rect = ref.current.getBoundingClientRect();
    };

    const getRandomString = (length) => {
      let result = "";
      let characters = "1335644^^£#$+-/*";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const loopRender = () => {
      if (!requestId) {
        requestId = requestAnimationFrame(() => {
          render();
          requestId = undefined;
        });
      }
    };

    const stopRendering = () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = undefined;
      }
    };

    const render = () => {
      const scrollDiff = {
        x: scrollVal.x - window.scrollX,
        y: scrollVal.y - window.scrollY,
      };

      renderedStyles["x"].current = mousepos.x - (scrollDiff.x + rect.left);
      renderedStyles["y"].current = mousepos.y - (scrollDiff.y + rect.top);

      for (const key in renderedStyles) {
        renderedStyles[key].previous = lerp(
          renderedStyles[key].previous,
          renderedStyles[key].current,
          renderedStyles[key].amt
        );
      }

      gsap.set(ref.current, {
        "--x": renderedStyles["x"].previous,
        "--y": renderedStyles["y"].previous,
      });

      gsap.set(ref.current.querySelector(".grid__item-img"), {
        "--x": renderedStyles["x"].previous,
        "--y": renderedStyles["y"].previous,
      });

      // Update randomString on each render
      const randomString = getRandomString(2000);
      ref.current.querySelector(".grid__item-img-deco").innerHTML =
        randomString;

      loopRender();
    };

    const initEvents = () => {
      window.addEventListener("resize", calculateSizePosition);

      ref.current.addEventListener("mousemove", (e) => {
        getMousePos(e);
        loopRender();
      });

      ref.current.addEventListener("mouseenter", () => {
        gsap.to(ref.current.querySelector(".grid__item-img-deco"), {
          duration: 0.5,
          ease: "power3",
          opacity: 1,
        });
        loopRender();
      });

      ref.current.addEventListener("mouseleave", () => {
        gsap.to(ref.current.querySelector(".grid__item-img-deco"), {
          duration: 0.5,
          ease: "power3",
          opacity: 0,
        });
        stopRendering();
      });
    };

    calculateSizePosition();
    initEvents();
  };

  useEffect(() => {
    refs.forEach((ref) => setupMouseTracking(ref));
  }, []);

  return (
    <div className="grid">
 {images.map((e,i)=>(
  <div key={i} className="grid__item">
  <div ref={refs[i]} className="grid__item-img div">
    <div className="grid__item-img-deco"></div>
  <img className="grid-image"  src={e} alt={i}/>
  </div>
</div>
 ))}
    




      
    </div>
  );
};

export default Skills;

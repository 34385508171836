import React, { useEffect, useRef, useState } from "react";
import TypeWriter from "../typeWriter/TypeWriter.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Slide from "../slide/Slide.js";
import "./project.css";
import RandomLetter from "../randomLetter/RandomLetter.js";
import CustomCanvasCursor from "../cursor/Cursor.js";
gsap.registerPlugin(ScrollTrigger);

const Deneme = ({ mainImage,images, name, desc,data }) => {
  const revealRef = useRef(null);
  const canvasWrapRef = useRef(null);
  const canvasRef = useRef(null);
  const innerRef = useRef(null);

  const img = useRef(null);
  const imgRatio = useRef(0);
  const ctx = useRef(null);
  const pxFactorValues = [1, 2, 4, 9, 16, 25, 36, 49, 64, 81, 100];
  const pxIndex = useRef(0);

  const [showDetails, setShowDetails] = useState(false);

  console.log(mainImage);

  useEffect(() => {
    const canvasElement = document.createElement("canvas");
    canvasRef.current = canvasElement;
    const canvasWrapElement = canvasWrapRef.current;
    canvasWrapElement.appendChild(canvasElement);

    img.current = new Image();
    img.current.src = mainImage;

    img.current.onload = () => {
      const imgWidth = img.current.width;
      const imgHeight = img.current.height;
      imgRatio.current = imgWidth / imgHeight;
      setCanvasSize();
      render();

      initEvents();
    };

    ctx.current = canvasElement.getContext("2d");

    return () => {
      window.removeEventListener("resize", handleResize);
      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  const handleResize = () => {
    setCanvasSize();
    render();
  };

  const setCanvasSize = () => {
    const canvasContainerWidth = canvasWrapRef.current.offsetWidth;
    const canvasContainerHeight = canvasWrapRef.current.offsetHeight;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const canvasWidth = canvasContainerWidth * devicePixelRatio;
    const canvasHeight = canvasContainerHeight * devicePixelRatio;
    canvasRef.current.width = canvasWidth;
    canvasRef.current.height = canvasHeight;
    ctx.current.scale(devicePixelRatio, devicePixelRatio);
  };

  const render = () => {
    const canvasWrapElement = canvasWrapRef.current;
    const canvasElement = canvasRef.current;

    const offsetWidth = canvasWrapElement.offsetWidth;
    const offsetHeight = canvasWrapElement.offsetHeight;
    const w = offsetWidth + offsetWidth * 0.05;
    const h = offsetHeight + offsetHeight * 0.05;

    let newWidth = w;
    let newHeight = h;
    let newX = 0;
    let newY = 0;

    if (newWidth / newHeight > imgRatio.current) {
      newHeight = Math.round(w / imgRatio.current);
      newY = (h - newHeight) / 2;
    } else {
      newWidth = Math.round(h * imgRatio.current);
      newX = (w - newWidth) / 2;
    }

    let pxFactor = pxFactorValues[pxIndex.current];
    const size = pxFactor * 0.01;

    ctx.current.mozImageSmoothingEnabled = size === 1 ? true : false;
    ctx.current.webkitImageSmoothingEnabled = size === 1 ? true : false;
    ctx.current.imageSmoothingEnabled = size === 1 ? true : false;

    ctx.current.clearRect(0, 0, canvasElement.width, canvasElement.height);
    ctx.current.drawImage(img.current, 0, 0, w * size, h * size);
    ctx.current.drawImage(
      canvasElement,
      0,
      0,
      w * size,
      h * size,
      newX,
      newY,
      newWidth,
      newHeight
    );
  };
  const animatePixels = () => {
    if (pxIndex.current < pxFactorValues.length) {
      setTimeout(
        () => {
          render();
          pxIndex.current++;
          animatePixels();
        },
        pxIndex.current === 0 ? 500 : 100
      );
    } else {
      pxIndex.current = pxFactorValues.length - 1;
      gsap.set(canvasWrapRef.current, {
        zIndex: "1",
      });
      gsap.set(canvasRef.current, {
        opacity: 0,
        display:"none",
        pointerEvents: "none"
      });
    }
  };

  const initEvents = () => {
    window.addEventListener("resize", handleResize);

    ScrollTrigger.create({
      trigger: revealRef.current,
      start: "top+=20% bottom",
      onEnter: () => {
        animatePixels();
      },
      once: true,
    });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: revealRef.current,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      })
      .to(innerRef.current, {
        ease: "none",
        yPercent: -100,
      });

    ScrollTrigger.create({
      trigger: revealRef.current,
      start: "top bottom",
      onEnter: () => {
        gsap.set(canvasWrapRef.current, {
          opacity: 1,
        });
      },
      once: true,
    });

    if (revealRef.current) {
      revealRef.current.classList.add("custom-reveal-class");
    }
  };

  const [click, setClick] = useState(false);

  useEffect(() => {
    if (canvasWrapRef.current) {
      const canvas = canvasWrapRef.current;

      if (click) {
        canvas.style.clipPath = "polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%)";
      } else {
        canvas.style.clipPath = "polygon(0 100%, 100% 100%, 100% 0, 0 0)";
      }
      canvasRef.current.classList.add('custom-canvas')
    }
  }, [click]);

  const handleClick = () => {
    setClick(true);
    setTimeout(() => {
      setClick(false);
    }, 3000);
  };

  return (
    <div className="projects-container">
      <CustomCanvasCursor  />
      
     
      <div
        className={showDetails ? "projects-wrapper-active" : "projects-wrapper"}
      >
        <div className={showDetails ? "projects-wrapper-top" : null}>
          <div ref={revealRef}>
            <div
              style={{ backgroundImage: `url(${mainImage})`}}
              onClick={() => {
                setTimeout(() => {
                  setShowDetails(!showDetails);
                  handleClick();
                }, 2000);
                setClick(!click);
              }}
              ref={canvasWrapRef}
              className={showDetails ? "active-canvas" : "canvas"}
            ></div>
          </div>

          <div
            className={
              showDetails ? "project-details-active" : "project-details"
            }
          >
            <h1>
              {showDetails ? (
                <RandomLetter showDetails={showDetails} word={name} />
              ) : (
                ""
              )}
            </h1>
            <span>{showDetails ? <TypeWriter content={desc} /> : null}</span>
          </div>
        </div>

        <div className="project-slider">
          <Slide images={images}/>
        </div>
      </div>
    </div>
  );
};

export default Deneme;

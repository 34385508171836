import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/swiper.min.css";
import "./slide.css";
import Model from "../Model/Model";
import { Swiper, SwiperSlide } from "swiper/react";

const Slide = ({ images }) => {
  const [modelClick, setModalClick] = useState(false);
  const [backGrounds, setBackGrounds] = useState([]);
  const [index, setIndex] = useState([]);
  useEffect(() => {
    setBackGrounds(images);

  }, [images]);

  return (
    <div className="slider">
      <Swiper
        slidesPerView={3}
        loop={true}
        updateOnWindowResize={true}
        breakpoints={{ 700: { slidesPerView: 2 } }}
        onRealIndexChange={(e) => {
          console.log(e);
        }}
      >
        {backGrounds.map((bg, i) => (
          <SwiperSlide
            onClick={() => {
              setModalClick(!modelClick);
              setIndex(i)
            }}
            onMouseEnter={()=>setIndex(i)}
            key={i}
          >
            <img className="images" src={bg} alt={i} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Model
        setModalClick={setModalClick}
        images={images}
        index={index}
        modelClick={modelClick}
      />
    </div>
  );
};

export default Slide;

import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./model.css";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { AsciiEffect } from "three-stdlib";
import { useTexture } from "@react-three/drei";
import "../../node_modules/swiper/swiper.min.css";

const Model = ({ modelClick, setModalClick, index, images }) => {
  const [currentIndex, setCurrentIndex] = useState();
  const [currentImage, setCurrentImage] = useState(images[index]);

  const [backGrounds, setBackGrounds] = useState([]);
  useEffect(() => {
    setBackGrounds(images);
    setCurrentIndex(index);
  }, [images, index]);

  useEffect(() => {
    setCurrentImage(images[currentIndex]);
  }, [currentIndex, images]);

  const handleNext = () => {
    if (currentIndex < backGrounds.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  };
  
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(backGrounds.length - 1);
    }
  };
  

  return (
    <div
      style={
        modelClick
          ? {
              position: "fixed",
              display: "block",
              zIndex: "999",
              width: "100%",
              height: "100vh",
              left: "0",
              right: "0",
              top: "0",
              background:
                "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 100%)",
              scroll: "none",
            }
          : { display: "none" }
      }
      className="modal"
    >
      <div
        className="model-container"
        onClick={() => {
          setModalClick(!modelClick);
        }}
      >
        <div className="modal-slider">
          <div>
            <CanvasWithImage imageUrl={currentImage} />
            <Canvas
              style={{
                width: "100%",
                height: "100%",
                padding: "0",
                margin: "0",
                position: "absolute",
                objectFit: "cover",
                top: "0",
              }}
            >
              <color attach="background" args={["black"]} />
              <fog attach="fog" args={["black", 15, 20]} />
              <Suspense fallback={null}>
                <ambientLight intensity={1} />
                <spotLight position={[0, 0, 0]} intensity={1} />
                <directionalLight position={[0, 0, 0]} intensity={1} />
                <AsciiRenderer fgColor="white" bgColor="black" />
                <MaskedContent textureUrl={currentImage} />
              </Suspense>
            </Canvas>
          </div>
        </div>
      </div>
      <div onClick={()=>handlePrevious()} className="prev-btn">&#10094;</div>
      <div onClick={()=>handleNext()} className="next-btn">&#10095;</div>
    </div>
  );
};

export default Model;

const CanvasWithImage = ({ imageUrl }) => {
  const canvasRef = useRef();
  const imageRef = useRef();
  const erasedAreasRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();

    image.src = imageUrl;

    image.onload = () => {
      canvas.width = image.width; // Canvas genişliğini resim genişliğiyle uyumlu hale getir
      canvas.height = image.height; // Canvas yüksekliğini resim yüksekliğiyle uyumlu hale getir

      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = "high";
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      imageRef.current = image;
    };
  }, [imageUrl]);

  let animationFrameId = null;
  const handleMouse = (e) => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    animationFrameId = requestAnimationFrame(() => {
      const { clientX, clientY } = e;
      const { left, top, width, height } = e.target.getBoundingClientRect();

      const size = 50;
      const offsetX =
        ((clientX - left) / width) * canvasRef.current.width - size / 4;
      const offsetY =
        ((clientY - top) / height) * canvasRef.current.height - size / 4;

      const x = Math.floor(offsetX / size) * size + 1;
      const y = Math.floor(offsetY / size) * size + 1;

      const context = canvasRef.current.getContext("2d");

      erasedAreasRef.current.forEach((erasedArea) => {
        const { x, y, size } = erasedArea;
        context.drawImage(imageRef.current, x, y, size, size, x, y, size, size);
      });

      context.clearRect(x, y, size, size);

      const erasedArea = { x, y, size };
      erasedAreasRef.current.push(erasedArea);
    });
  };

  return (
    <canvas
      onMouseMove={handleMouse}
      style={{
        width: " 100%",
        height: "auto",
        position: "relative",
        zIndex: "99",
      }}
      ref={canvasRef}
    />
  );
};

function MaskedContent({ textureUrl }) {
  const texture = useTexture(textureUrl);
  const imgRef = useRef();

  return (
    <mesh position={[0, 0, 0]} scale={[1.1, 1.1, 1]} ref={imgRef}>
      <planeBufferGeometry args={[15, 7]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

function AsciiRenderer({
  renderIndex = 1,
  bgColor = "black",
  fgColor = "white",
  characters = " .,10+-*/",
  invert = true,
  color = false,
  resolution = 0.15,
}) {
  const { size, gl, scene, camera } = useThree();

  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, {
      invert,
      color,
      resolution,
    });
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "-1px";
    effect.domElement.style.left = "-1px";
    effect.domElement.style.pointerEvents = "none";
    return effect;
  }, [characters, invert, color, resolution]);

  // Styling
  useLayoutEffect(() => {
    effect.domElement.style.color = fgColor;
    effect.domElement.style.backgroundColor = bgColor;
  }, [fgColor, bgColor]);

  // Append on mount, remove on unmount
  useEffect(() => {
    gl.domElement.style.opacity = "0";
    gl.domElement.parentNode.appendChild(effect.domElement);
    return () => {
      gl.domElement.style.opacity = "1";
      gl.domElement.parentNode.removeChild(effect.domElement);
    };
  }, [effect]);

  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  useFrame((state) => {
    effect.render(scene, camera);
  }, renderIndex);
}

import React, { useEffect, useRef, useState } from "react";
import "./randomletter.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const RandomLetterAnimation = ({ word, showDetails }) => {
  const randomletter = useRef(null);
  const theLetters = "abcdefghijklmnopqrstuvwxyz#%&^+=-";
  const speed = 10;
  const increment = 8;
  const clen = word.length;
  let si = 0;
  let stri = 0;
  let block = "";
  let fixed = "";

  const [animationText, setAnimationText] = useState("");

  const rustle = (i) => {
    setTimeout(function () {
      if (i > 0) {
        rustle(i - 1);
      }
      nextFrame(i);
      si = si + 1;
    }, speed);
  };

  const nextFrame = () => {
    for (let i = 0; i < clen - stri; i++) {
      // Random number
      const num = Math.floor(theLetters.length * Math.random());
      // Get random letter
      const letter = theLetters.charAt(num);
      block = block + letter;
    }
    if (si === increment - 1) {
      stri++;
    }
    if (si === increment) {
      fixed = fixed + word.charAt(stri - 1);
      si = 0;
    }
    setAnimationText(fixed + block);
    block = "";
  };

  useEffect(() => {
    const scrollTrigger = ScrollTrigger.create({
      trigger: randomletter.current,
      start: "bottom bottom",
      onEnter: () => {
        rustle(clen * increment + 1);
      },
      once: true,
    });
  
    return () => {
      scrollTrigger.kill();
    };
  }, [clen]);

  return (
    <div
      style={{ position: "relative", maxWidth: "50px" }}
      className="random-letter1"
      ref={randomletter}
    >
      {animationText}
    </div>
  );
};

export default RandomLetterAnimation;

import React, { useEffect, useState } from "react";
import './typewriter.css'


const TypeWriter = ({ content = "", speed = 30 }) => {
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);


  useEffect(() => {
    const setAnim = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex >= content.length - 1) {
          clearInterval(setAnim);
          return prevIndex;
        }
        return prevIndex + 1;
      });

    }, speed);

    return () => {
      clearInterval(setAnim);
    };
  }, [content, speed]);

  useEffect(() => {
    setTimeout(()=>{
        setDisplayedContent(content.slice(0, index + 1));
    },5000)
    
  }, [index, content]);

  return (
    <div>
      <p className="type-writer">{displayedContent}</p>
    </div>
  );
};

export default TypeWriter;

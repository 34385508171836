import About from "./About/About";
import "./App.css";
import Contact from "./Contact/Contact";
import Deneme from "./project/Project";
import Intro from "./intro/Intro";
import Skills from "./skills/Skills";
import api from './projectfile/project.json'

function App() {
  return (
    <div id="over">
      <Intro />
      <About />
      <Skills />
      {api.projects.map((e, i) => (
        <Deneme
          mainImage={e.images[0]}
          images={e.images}
          name={e.name}
          desc={e.description}
          key={i}
          data={i}
        />
      ))}
      <Contact />
    </div>
  );
}

export default App;

import React from "react";
import "./contact.css";
import RandomLetterAnimation from "../randomLetter/RandomLetter";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3
            style={{
              position: "relative",
              width: "90px",
            }}
          >
            <RandomLetterAnimation word={"contact me"} />
          </h3>
        </div>
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
          <a style={{textDecoration:"none", color:"#fff", margin:"10px 0"}}href="mailto:harun-hardal@hotmail.com">harun-hardal@hotmail.com</a>
          <a style={{textDecoration:"none", color:"#fff", margin:"10px 0"}}href="https://github.com/HarunHardal">GitHub</a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

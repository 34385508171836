import React, {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useTexture, OrbitControls } from "@react-three/drei";
import { AsciiEffect } from "three-stdlib";
import "./intro.css";

export default function App() {
  return (
    <>
      <div id="ascii-content"></div>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "80vh",
          overflow: "hidden",
        }}
      >
        <Canvas className="intro-scene">
          <color attach="background" args={["black"]} />
          <fog attach="fog" args={["black", 15, 20]} />
          <Suspense fallback={null}>
            <MaskedContent />
            <ambientLight intensity={0.5} />
            <spotLight position={[0, 10, 0]} intensity={0.3} />
            <directionalLight position={[-50, 0, -40]} intensity={0.7} />
          </Suspense>
          <OrbitControls />
          <AsciiRenderer fgColor="white" bgColor="black" />
        </Canvas>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 285 80"
          preserveAspectRatio="xMidYMid slice"
        >
          <defs>
            <mask id="mask" x="0" y="0" width="200%" height="100%">
              <rect
                x="0"
                style={{ fill: "white" }}
                y="0"
                width="100%"
                height="100%"
              />
              <text x="72" y="50" id="scrolling-text">
                Hi Selam やあ привет नमस्ते
              </text>
            </mask>
          </defs>
          <rect
            x="0"
            style={{ fill: "black" }}
            y="0"
            width="100%"
            height="100%"
          />
        </svg>
      </div>
    </>
  );
}

function MaskedContent() {
  const [image] = useTexture(["/indir.png"]);
  const meshRef = useRef();
  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();
    meshRef.current.rotation.x = time / 15; // x ekseninde dönüş
    meshRef.current.rotation.y = time / 15; // y ekseninde dönüş
  });

  return (
    <mesh position={[0, 0, 0]} ref={meshRef}>
      <sphereGeometry args={[4.5, 100, 100]} />
      <meshStandardMaterial map={image} />
    </mesh>
  );
}
function AsciiRenderer({
  renderIndex = 1,
  bgColor = "black",
  fgColor = "white",
  characters = " .:,+;*%@#45837",
  invert = true,
  color = false,
  resolution = 0.15,
}) {
  // Reactive state
  const { size, gl, scene, camera } = useThree();

  // Create effect
  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, {
      invert,
      color,
      resolution,
    });
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "-1px";
    effect.domElement.style.left = "-1px";
    effect.domElement.style.pointerEvents = "none";
    return effect;
  }, [characters, invert, color, resolution]);

  // Styling
  useLayoutEffect(() => {
    effect.domElement.style.color = fgColor;
    effect.domElement.style.backgroundColor = bgColor;
  }, [fgColor, bgColor]);

  // Append on mount, remove on unmount
  useEffect(() => {
    gl.domElement.style.opacity = "0";
    gl.domElement.parentNode.appendChild(effect.domElement);
    return () => {
      gl.domElement.style.opacity = "1";
      gl.domElement.parentNode.removeChild(effect.domElement);
    };
  }, [effect]);

  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  useFrame((state) => {
    effect.render(scene, camera);
  }, renderIndex);

}
